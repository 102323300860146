@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Roboto", sans-serif;
}
.btn {
  background-color: #0080ff;
}
.btn:active,
.btn:focus {
  outline: black !important;
  box-shadow: none;
  background-color: #0080ff;
  border: 0px !important;
}
.form-control:active,
.form-control:focus,
.form-control:focus:active {
  outline: black !important;
  box-shadow: none;
  border-radius: 0px;
  border: 2px solid #0080ff;
  color: #fff;
}

.navbar {
  background-color: #fff;
  box-shadow: 0px 1px 12px -3px rgb(211, 211, 211);
}
.navbar .navbar-brand img {
  width: 70px;
}

.navbar .nav-item {
  margin-left: 20px;
  font-weight: 600;
  font-size: 16px;
}

.hero-section {
  margin-top: 50px;
  /* height: 90vh; */
}
.hero-section h1 {
  padding-left: 40px;
  font-size: 70px;
  font-weight: 700;
  margin-top: 100px;
}
.hero-section span {
  font-size: 50px;
  margin-top: -20px;
  color: #fff;
  background-color: #0080ff;
  padding: 5px 15px;
  display: inline-block;
}
.hero-section p {
  padding-left: 40px;
  font-size: 15px;
  color: #939393;
}
.hero-section a {
  background-color: #0080ff;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  margin-left: 40px;
  margin-top: 40px;
  border-radius: 5rem;
  font-weight: 500;
  border: 2px solid #fff;
  box-shadow: 5px 5px 25px rgb(0 0 0 / 16%);
}
.hero-section a:hover {
  background-color: #fff;
  color: #0080ff;
  transition: 0.5s;
  border: 2px solid #0080ff;
}
.hero-section img {
  /* height: 550px; */
  margin-top: 50px;
}

@media (max-width: 800px) {
  .hero-section h1 {
    padding-left: 20px;
    font-size: 40px;
  }
  .hero-section span {
    font-size: 30px;
  }
  .hero-section p {
    padding-left: 20px;
  }
  .hero-section a {
    margin-left: 20px;
  }
}

/* Highlights */

.highlights h1 {
  font-size: 40px;
  font-weight: 900;
}
.highlights h1 span {
  color: #0080ff;
}
.highlights h2 {
  font-size: 20px;
  opacity: 0.5;
}
.highlights .card {
  border: 1px solid #eef0f4;
  border-radius: 10px;
  transition: 0.5s;
}
.highlights .card:hover {
  box-shadow: 0px -3px 0px 0px rgb(234 76 137 / 0%),
    0px 40px 40px 0px rgb(14 17 51 / 10%);
  border-top: 4px solid #0080ff;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition: 0.5s;
}

.highlights h6 {
  font-size: 25px;
  font-weight: 600;
}
.highlights p {
  color: rgba(0, 0, 0, 0.705);
}

/* About */

.about {
  margin-top: 100px;
}
.about img {
  height: 350px;
  border-radius: 10px;
}
.about h6 {
  font-size: 20px;
  padding-bottom: 20px;
  font-weight: 700;
  color: #939393;
}
.about h1 {
  font-size: 45px;
  font-weight: 700;
}
.about h1 span {
  color: #0080ff;
}
.about p {
  padding-top: 20px;
  font-size: 16px;
  color: #2b2a2abb;
}
.about a {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5rem;
  text-decoration: none;
  margin-top: 30px;
  display: inline-block;
  box-shadow: 5px 5px 25px rgb(0 0 0 / 16%);
}
.about a:hover {
  background-color: #0080ff;
  transition: 0.5s;
}
@media (max-width: 800px) {
  .about img {
    height: 300px;
  }
  .about h2 {
    margin-top: 30px;
    display: inline-block;
    font-size: 30px;
  }
}

/* Products */

.products {
  margin-top: 100px;
}
.products h2 {
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  padding-bottom: 10px;
}
.products h2 span {
  color: #0080ff;
}
.products .card {
  /* box-shadow: 2px 4px 8px 0 rgb(0 0 0 / 10%); */
  border: 0px;
}
.products .card .card-body {
  padding: 10px;
}
.products .card .card-title {
  font-size: 20px;
}
.products p {
  color: #2b2a2abb;
  font-size: 14px;
}
.products a {
  color: #0080ff;
  font-weight: 700;
  padding: 5px 15px;
  border-radius: 5px;
  text-decoration: none;
}
.products a:hover {
  background-color: #0080ff;
  color: #fff;
  border-radius: 50px;
  transition: 0.5s;
}

.products img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

/* Contact */
.contact {
  background-color: #442eeb;
  color: #fff;
  padding-top: 40px;
  margin-top: 50px;
  text-align: center;
}
.contact h6 {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.795);
}
.contact h5 {
  font-size: 18px;
}
.contact i {
  font-size: 30px;
  color: #442eeb;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  width: 50px;
  margin-bottom: 30px;
}
.contact h4 {
  font-size: 15px;
  padding-top: 30px;
}
@media (max-width: 800px) {
  .contact h4 {
    padding-top: 30px;
    font-size: 15px;
  }
  .contact i {
    margin-bottom: 10px;
  }
}

.welcomepage img {
  height: 600px;
  border: 3px solid #0080ff;
}
.welcomepage marquee {
  background-color: #0080ff;
  color: #fff;
  font-size: 20px;
  padding: 10px;
}
.welcomepage h3 {
  font-weight: 800;
  color: #0080ff;
  padding-bottom: 10px;
  text-decoration: underline;
}
.welcomepage h6 {
  font-size: 20px;
}
.welcomepage a {
  font-size: 20px;
  font-weight: 700;
}
.welcomepage .border {
  border-radius: 2px;
  box-shadow: 2px 4px 8px 0 rgb(0 0 0 / 10%);
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  padding: 25px 6% 3%;
  margin-top: 40px;
}

.contact-home h6 {
  font-size: 20px;
  font-weight: 400;
}
.contact-home h6 span {
  font-weight: 700;
  color: #442eeb;
}
.contact-home h5 {
  padding-top: 20px;
}
.contact-home h5 span {
  font-weight: 700;
  color: #442eeb;
}
.contact-home p {
  font-weight: 700;
  font-size: 25px;
  padding-top: 50px;
  color: #442eeb;
}
.contact-home h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

@media (max-width: 800px) {
  .contact-home img {
    margin: 0px;
  }
  .welcomepage img {
    height: auto;
  }
}

.navbar-new {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 1px 12px -3px rgb(211, 211, 211);
}
.navbar-new img {
  width: 70px;
}
.navbar-new .contact-btn {
  text-decoration: none;
  display: inline-block;
  color: #fff;
  float: right;
  font-size: 18px;
  font-weight: 700;
  background-color: #0080ff;
}

